<template>
  <div class="set-vision">
    <!-- 播放器 -->
    <div id="player-content"></div>
    <!-- 渲染画布 -->
    <div id="render-canvas"></div>
    <!-- 选取默认视觉悬浮 -->
    <div class="vision-select">
      <el-button type="warning" class="select-view-btn" @click="choiceVision">设置当前视觉为初始视觉</el-button>
    </div>
  </div>
</template>

<script>
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
export default {
  name: 'SetVision',
  props: {
    cameraItem: Object
  },
  data () {
    return {
      // 场景
      Scene: null,
      // 相机
      Camera: null,
      // 相机参数
      CameraParams: {
        fov: 75,
        near: 0.1,
        far: 1000
      },
      // 控制器
      OrbitControls: null,
      // 渲染器
      Renderer: null,
      // 渲染画布
      renderCanvasDom: null,
      // 视频播放器DOM
      playerDom: null,
      // 外部球体
      OutBall: {
        // 球体
        Geometry: null,
        // 贴图
        Texture: null,
        // 材质
        Material: null,
        // 合成材质的球体
        Mesh: null
      },
      // 播放器
      Player: null
    }
  },
  created () {
  },
  methods: {
    // 根据传递的场景信息覆盖默认的材质 和 相机焦点
    dataCover (cameraItem) {
      // 1.1 根据url判断场景类型(视频，图片，模型)
      const urlType = cameraItem.url.substring(cameraItem.url.lastIndexOf('.') + 1)
      if (urlType === 'jpg' || urlType === 'png') {
        // 1.2 图片类型
        const textureLoaderModel = new this.THREE.TextureLoader()
        textureLoaderModel.setCrossOrigin('Anonymous')
        this.OutBall.Texture = textureLoaderModel.load(cameraItem.url, (texture) => {
          this.OutBall.Material.map = texture
          this.OutBall.Material.map.needsUpdate = true
        })
      } else if (urlType === 'm3u8' || urlType === 'flv' || urlType === 'mp4') {
        // 1.3.1 先初始化播放器并播放视频
        this.playerInit(cameraItem)
        // 1.3.2 视频类型
        this.OutBall.Texture = new this.THREE.VideoTexture(this.playerDom)
        this.OutBall.Texture.image.crossOrigin = ''
        this.OutBall.Material.map = this.OutBall.Texture
        this.OutBall.Material.map.needsUpdate = true
      }
      // 设置相机焦点
      this.Camera.position = new this.THREE.Vector3(cameraItem.visionX, cameraItem.visionY, cameraItem.visionZ)
      this.Camera.updateProjectionMatrix()
      this.OrbitControls.update()
    },
    threeInit () {
      // 渲染画布
      this.renderCanvasDom = document.getElementById('render-canvas')
      // 创建场景
      this.Scene = new this.THREE.Scene()
      // 创建相机
      this.Camera = new this.THREE.PerspectiveCamera(this.CameraParams.fov, this.renderCanvasDom.clientWidth / this.renderCanvasDom.clientHeight, this.CameraParams.near, this.CameraParams.far)
      // 1.创建外部球体和材质
      this.OutBall.Geometry = new this.THREE.SphereGeometry(200, 60, 60)
      // 1.1 缩放球体
      this.OutBall.Geometry.scale(-1, 1, 1)
      // 1.2 外部球体材质
      const textureLoaderModel = new this.THREE.TextureLoader()
      textureLoaderModel.setCrossOrigin('Anonymous')
      this.OutBall.Texture = textureLoaderModel.load(require('../../../../../assets/img/Nosignal.jpg'))
      this.OutBall.Material = new this.THREE.MeshBasicMaterial({ map: this.OutBall.Texture })
      // 1.3 组合材质和球体，添加进入场景
      this.OutBall.Mesh = new this.THREE.Mesh(this.OutBall.Geometry, this.OutBall.Material)
      this.OutBall.Mesh.name = '外部球体'
      this.Scene.add(this.OutBall.Mesh)
      // 2.创建热点层球体和材质

      // 3.处理页面自适应缩放和事件监听
      // 3.1添加窗口大小变化函数
      window.addEventListener('resize', this.onWindowResize)
      // 3.2 添加鼠标滚轮监听事件
      this.renderCanvasDom.addEventListener('mousewheel', this.onMouseWheel, false)
      // 创建渲染器 并渲染画面到画布
      this.Renderer = new this.THREE.WebGLRenderer()
      this.Renderer.setPixelRatio(window.devicePixelRatio)
      this.Renderer.setSize(this.renderCanvasDom.clientWidth, this.renderCanvasDom.clientHeight)
      this.renderCanvasDom.appendChild(this.Renderer.domElement)

      // 4 添加控制器
      this.addOrbitControls()

      // 调用动画渲染
      this.animate()
    },
    // 播放器初始化
    playerInit (cameraItem) {
      // 清空视频播放器的dom
      document.getElementById('player-content').innerHTML = ''
      // 1.1 需要判断链接是否为直播链接
      const urlType = cameraItem.url.substring(cameraItem.url.lastIndexOf('.') + 1)
      this.Player = new this.TcPlayer('player-content', {
        [urlType]: cameraItem.url, // 请替换成实际可用的播放地址
        autoplay: true, // iOS 下 safari 浏览器是不开放这个能力的
        live: urlType !== 'mp4',
        wording: {
          2032: '请求视频失败，请检查网络',
          2048: '请求m3u8文件失败，可能是网络错误或者跨域问题'
        }
      })
      // 获取视频源并播放视频
      const videoDom = document.getElementById('player-content')
      this.playerDom = videoDom.getElementsByTagName('video')[0]
      this.playerDom.crossOrigin = ''
      this.Player.play()
    },
    // 创建控制器
    addOrbitControls () {
      // 创建控制器
      this.OrbitControls = new OrbitControls(this.Camera, this.Renderer.domElement)
      this.OrbitControls.listenToKeyEvents(this.renderCanvasDom)
      // 设置镜头阻尼
      this.OrbitControls.enableDamping = true
      this.OrbitControls.dampingFactor = 0.05
      // 禁止摄像机平移
      this.OrbitControls.enablePan = false
      this.OrbitControls.screenSpacePanning = true
      this.OrbitControls.enableZoom = false
      this.OrbitControls.minDistance = 10
      this.OrbitControls.maxDistance = 200
    },
    // 监听窗口大小变化
    onWindowResize () {
      this.Camera.aspect = this.renderCanvasDom.clientWidth / this.renderCanvasDom.clientHeight
      this.Camera.updateProjectionMatrix()
      this.Renderer.setSize(this.renderCanvasDom.clientWidth, this.renderCanvasDom.clientHeight)
    },
    // 监听鼠标滚轮事件
    onMouseWheel (event) {
      event.stopPropagation()
      if (event.wheelDelta) { // 判断浏览器IE，谷歌滑轮事件
        if (event.wheelDelta > 0) { // 当滑轮向上滚动时
          this.CameraParams.fov -= (this.CameraParams.near < this.CameraParams.fov ? 1 : 0)
        }
        if (event.wheelDelta < 0) { // 当滑轮向下滚动时
          this.CameraParams.fov += (this.CameraParams.fov < this.CameraParams.far ? 1 : 0)
        }
      } else if (event.detail) { // Firefox滑轮事件
        if (event.detail > 0) { // 当滑轮向上滚动时
          this.CameraParams.fov -= 1
        }
        if (event.detail < 0) { // 当滑轮向下滚动时
          this.CameraParams.fov += 1
        }
      }
      // 改变fov值，并更新场景的渲染
      this.Camera.fov = this.CameraParams.fov
      this.Camera.updateProjectionMatrix()
      this.OrbitControls.update()
    },
    // 渲染动画
    animate () {
      requestAnimationFrame(this.animate)
      this.Renderer.render(this.Scene, this.Camera)
    },
    // 业务逻辑
    // 设置初始视觉
    async choiceVision () {
      const formData = {
        m_id: this.cameraItem.m_id,
        x: this.Camera.position.x,
        y: this.Camera.position.y,
        z: this.Camera.position.z
      }
      const { data: res } = await this.$http.post('/panorama-set-camera-vision', formData)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getProjectInfo()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取项目信息
    async getProjectInfo () {
      const { data: res } = await this.$http.get('/panorama-project', { params: { mid: this.$route.params.project_id } })
      if (res.status === 200) {
        this.$store.commit('updatePanoramaAll', res.data.projectInfo)
        this.$store.commit('updateSceneList', res.data.sceneList)
        this.$store.commit('updateCameraList', res.data.cameraList)
        this.$store.commit('updateHotsList', res.data.hotsList)
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  mounted () {
    this.threeInit()
  },
  // 监听父组件cameraItem值是否改变
  watch: {
    cameraItem: { // 深度监听，可监听到对象、数组的变化
      handler (newV, oldV) {
        this.dataCover(newV)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.set-vision{
  width: 100%;
  height: 100%;
}
#render-canvas{
  width: 100%;
  height: 100%;
}
.vision-select{
  position: absolute;
  left: 30%;
  right: 30%;
  top: 18%;
  width: 40%;
  text-align: center;
  height: 40%;
  pointer-events: none;
  background-image: url('../../../../../assets/img/frame.png');
  background-size: 100% 100%;
  border: 2px dashed #FFFFFF;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.vision-select .select-view-btn{
  pointer-events: auto;
  margin-bottom: 20px;
}
</style>
